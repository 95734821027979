import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    createRoutesFromElements,
    Route,
    ScrollRestoration,
  } from "react-router-dom";
  import Header from "./Components/Header/Header";
  import Footer from "./Components/Footer/Footer";
  import Home from "../src/Pages/Home/Home";
  
  import { ToastContainer } from "react-toastify";
  import About from "./Pages/About/About";
  import Contact from "./Pages/Contact/Contact";
  import Services from "./Pages/Services/Services";
  import Blog from "./Pages/Blog/Blog";
import ServicesFiziki from "./Pages/ServicesFiziki/ServicesFiziki";
import ServicesVideo from "./Pages/ServicesVideo/ServicesVideo";
import ServicesEvents from "./Pages/ServicesEvents/ServicesEvents";
import ServicesTehlukesizlik from "./Pages/ServicesTehlukesizlik/ServicesTehlukesizlik";
import Media from "./Pages/PhotoGallery/PhotoGallery";
import PhotoGallery from "./Pages/PhotoGallery/PhotoGallery";
import Video from "./Pages/Video/Video";
import News from "./Pages/News/News";
import NewsDetail from "./Pages/NewsDetail/NewsDetail";
import Laws from "./Pages/Laws/Laws";
import Partners from "./Pages/Partners/Partners";
import SosialProjects from "./Pages/SosialProjects/SosialProjects";
import SosialProjectsDetail from "./Pages/SosialProjectsDetail/SosialProjectsDetail";
import Press from "./Pages/Press/Press";
import Experience from "./Pages/Experience/Experience";
import Jobs from "./Pages/Jobs/Jobs";
import Rules from "./Pages/Rules/Rules";
import JobsDetails from "./Pages/JobsDetails/JobsDetails";
import ExperienceDetails from "./Pages/ExperienceDetails/ExperienceDetails";
import JobsApply from "./Pages/JobsApply/JobsApply";
import ExperienceApply from "./Pages/ExperienceApply/ExperienceApply";
  
  
  
  const Layout = () => {
    return (
      <div>
         <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> 
        <ScrollRestoration />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Layout />}>
          {/* ==================== Header Navlink Start here =================== */}
          <Route path="/" element={ <Home />}></Route>
          <Route path="/services" element={ <Services/>}></Route>
          <Route path="/services/fiziki" element={ <ServicesFiziki/>}></Route>
          <Route path="/services/video" element={ <ServicesVideo/>}></Route>
          <Route path="/services/events" element={ <ServicesEvents/>}></Route> 
          <Route path="/services/tehlukesizlik" element={ <ServicesTehlukesizlik/>}></Route>
          <Route path="/blogs" element={ <Blog/>}></Route>
          <Route path="/contact" element={ <Contact/>}></Route>
          <Route path="/about" element={ <About/>}></Route>
          <Route path="/laws" element={ <Laws/>}></Route>
          <Route path="/media/photo-gallery" element={ <PhotoGallery/>}></Route>
          <Route path="/media/video" element={ <Video/>}></Route>
          <Route path="/news" element={ <News/>}></Route>
          <Route path="/press" element={ <Press/>}></Route>
          <Route path="/about/partners" element={ <Partners/>}></Route>
          <Route path="/about/projects" element={ <SosialProjects/>}></Route>
          <Route path="/news/:id" element={ <NewsDetail/>}></Route>
          <Route path="/about/projects/:id" element={ <SosialProjectsDetail/>}></Route>
          <Route path="/karyera/experience" element={ <Experience/>}></Route>
          <Route path="/karyera/experience/:id" element={ <ExperienceDetails/>}></Route>
          <Route path="/karyera/experience/:id/apply" element={ <ExperienceApply/>}></Route>
          <Route path="/karyera/jobs/:id" element={ <JobsDetails/>}></Route>
          <Route path="/karyera/jobs/:id/apply" element={ <JobsApply/>}></Route>
          <Route path="/karyera/jobs" element={ <Jobs/>}></Route>
          <Route path="/karyera/rules" element={ <Rules/>}></Route>
        </Route>
      </Route>
    )
  );
  
  function App() { 
   
    return (
      <div className="font-bodyFont">
        <RouterProvider router={router} />
      </div>
    );
  }
  
  export default App;
// import React, { useState } from 'react'
// import { useTranslation } from 'react-i18next';
// import { logo } from '../../assets/images';


// const files = [
//   { name: 'Azərbaycan Respublikasının Konstitusiyası', url: '/konstitusiya.pdf' },
//   { name: 'Qeyri-dövlət (özəl) mühafizə fəaliyyəti haqqında', url: '/Qeyri_Dovlet-ozel-muhafize_fealiyyeti.pdf' },
//   { name: 'Azərbaycan Respublikasının Əmək məcəlləsi', url: '/AR-in_emek_mecellesi.pdf' },
//   { name: 'Azərbaycan Respublikasının Cinayət məcəlləsi', url: '/AR-in_cinayet_mecellesi.pdf' },
//   { name: 'Azərbaycan Respublikasının İnzibati Xətalar məcəlləsi', url: 'inzibati-xetalar.pdf' },
// ];

// const Laws = () => {
//   const { t, i18n } = useTranslation();

//   const [searchTerm, setSearchTerm] = useState('');

//   const filteredFiles = files.filter((file) =>
//     file.name.toLowerCase().includes(searchTerm.toLowerCase())
//   );
//   return (
 
//      <>
//          <section class="team-section">
//      <div class="auto-container">
     
//          {/* <!--Styled Heading--> */}
         
//          <div class="row clearfix">
             
//          <div className="p-4 w-[80%] mx-auto">
//       <div className="mb-4">
//         <input
//           type="text"
//           placeholder={t("Axtar...")}
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           className="w-full p-2 border rounded-md"
//         />
//       </div>
//       <div className="file-list space-y-4">
//         {filteredFiles.map((file, index) => (
//           <div key={index} className="file-item bg-white shadow-md rounded-lg p-4 flex justify-between items-center">
//             <div className='flex'> 
//             <img src={logo}></img>
//             <p className="text-lg font-semibold mt-2">{file.name}</p>
//             </div>
//             <a href={file.url} download>
//               <button className="download-button bg-[#086170] text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors">
//                 {t("Yüklə")}
//               </button>
//             </a>
//           </div>
//         ))}
//         {filteredFiles.length === 0 && (
//           <p className="text-center text-gray-500">{t("Fayl yoxdur")}</p>
//         )}
//       </div>
//     </div>
             
             
             
//          </div>
         
//      </div>
//  </section>
  
//          </>
//   )
// }

// export default Laws                        










































import React, { useState } from 'react'
import HeaderOther from '../../Components/HeaderOther/HeaderOther'
import { certificate1, certificate2, certificate3, logo, partners1, partners10, partners11, partners12, partners13, partners14, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, pdf, whyus, whyus2 } from '../../assets/images'
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';




const files = [
  { name: t("Azərbaycan Respublikasının Konstitusiyası"), url: '/konstitusiya.pdf' },
  { name: t("Qeyri-dövlət (özəl) mühafizə fəaliyyəti haqqında"), url: '/Qeyri_Dovlet-ozel-muhafize_fealiyyeti.pdf' },
  { name: t("Azərbaycan Respublikasının Əmək məcəlləsi"), url: '/AR-in_emek_mecellesi.pdf' },
  { name: t("Azərbaycan Respublikasının Cinayət məcəlləsi"), url: '/AR-in_cinayet_mecellesi.pdf' },
  { name: t("Azərbaycan Respublikasının İnzibati Xətalar məcəlləsi"), url: 'inzibati-xetalar.pdf' },
];
const Laws = () => {

    const { t, i18n } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');

  const filteredFiles = files.filter((file) =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
     <HeaderOther/>
     <div className='md:max-w-container max-w-containerSm mx-auto'>
     <p className='mt-[20px] mb-[30px] lg:mb-[60px] text-sm lg:text-base'><span className='text-[#DDB14C]'>{t("Ana səhifə")}</span>  /  {t("Qanunvericilik")}</p>
     </div>
     <div className=''> 
      <div className='md:max-w-container max-w-containerSm mx-auto'>
          <p className='text-[24px] lg:text-[32px] text-center mb-[30px] lg:mb-[40px] font-semibold'>{t("Qanunvericilik")}</p>   
      </div> 
    </div>
    <div className='text-[black] h-auto lg:h-[460px] mb-[40px] lg:mb-[283px]'>
      <div className='md:max-w-container max-w-containerSm lg: mx-auto'>
      <div >
     
          {/* <!--Styled Heading--> */}
        
          <div className="p-4 md:max-w-container max-w-full mx-auto">
  <div className="file-list space-y-4">
    {filteredFiles.map((file, index) => (
      <div
        key={index}
        className="file-item bg-[#D0D0D0] shadow-md rounded-lg p-4 flex flex-col md:flex-row justify-between items-center"
      >
        <div className="flex items-center">
          <img className="w-16 h-16" src={pdf} alt="file icon" />
          <p className="text-lg font-semibold mt-4 md:mt-0 ml-4">{file.name}</p>
        </div>
        <a href={file.url} download>
          <button className="download-button bg-[#DDB14C] h-[40px] w-[148px] rounded-xl text-[white] mt-4 md:mt-0 md:ml-5">
            {t("Yüklə")}
          </button>
        </a>
      </div>
    ))}
    {filteredFiles.length === 0 && (
      <p className="text-center text-gray-500">{t("Fayl yoxdur")}</p>
    )}
  </div>
</div>

             
          
         
     </div>
      </div>
    </div>
    </>
  )
}

export default Laws

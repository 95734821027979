import React from 'react'
import { discount1, discount2, discount3, hero1, hero2, heroBg } from '../../../assets/images';
import { useTranslation } from 'react-i18next';


const Hero = () => {

  const { t, i18n } = useTranslation();
  return (
     <div className="md:max-w-container max-w-containerSm mx-auto flex justify-between md:h-[633px] pb-[30px] md:pb-0 ">
      <div className='flex flex-col justify-center mx-auto text-center'>
      <h1 className='text-[20px] md:text-[50px] font-extrabold text-[white]'>SOS SECURİTY</h1>
      <h1 className='text-[20px] md:text-[50px] font-extrabold text-[white]'>{t("ETİBARLI MÜHAFİZƏNİN TƏMİNATÇISI!")}</h1>
        <p className='md:text-[20px] text-base text-[white]'>{t("Daxili İşlər Nazirliyinin lisenziyası əsasında fəaliyyət göstəririk")}</p>
       <div className='flex mx-auto mt-5'>
       <a href='/about'><button className='bg-[#DDB14C] mr-5 h-[40px] w-[148px] rounded-xl text-[white] mt-[10px]'>{t("Daha çox")}</button></a>
       <a href='/contact'><button className='border-2 border-[#DDB14C] h-[40px] w-[148px] rounded-xl text-[white] mt-[10px]'>{t("Əlaqə")}</button></a>
       </div>
      </div> 
  </div>
  )
} 

export default Hero 
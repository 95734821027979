import React from 'react'
import HeaderOther from '../../Components/HeaderOther/HeaderOther'
import { certificate1, certificate2, certificate3, partners1, partners10, partners11, partners12, partners13, partners14, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, whyus, whyus2 } from '../../assets/images'
import { useTranslation } from 'react-i18next';

const Partners = () => {

  const { t, i18n } = useTranslation();
  return (
    <>
     <HeaderOther/>
     <div className='max-w-container mx-auto'>
     <p className='mt-[20px] mb-[30px] lg:mb-[60px] text-sm lg:text-base'><span className='text-[#DDB14C]'>{t("Ana səhifə")}</span> / <span className='text-[#DDB14C]'>{t("Haqqımızda")}</span> /  {t("Tərəfdaşlar")}</p>
     </div>
     <div className=''> 
      <div className='max-w-container mx-auto'>
          <p className='text-[24px] lg:text-[32px] text-center mb-[30px] lg:mb-[40px] font-semibold'>{t("Tərəfdaşlar")}</p>   
      </div> 
    </div>
    <div className='text-[black] h-auto lg:h-[960px] mb-[40px] lg:mb-[283px]'>
      <div className='max-w-container lg: mx-auto'>
      <div className='h-[145px] grid grid-cols-4 gap-8'>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners1} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners2} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners3} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners4} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners5} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners6} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners7} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners8} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners9} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners10} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners11} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners12} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners13} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <img className='h-[66px] object-contain' src={partners14} alt="Partner 1" />
         </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Partners

import React from 'react'
import HeaderOther from '../../Components/HeaderOther/HeaderOther'
import {gallery1, gallery10, gallery11, gallery12, gallery13, gallery14, gallery15, gallery16, gallery17, gallery18, gallery19, gallery2, gallery20, gallery21, gallery22, gallery23, gallery24, gallery25, gallery26, gallery27, gallery3, gallery4, gallery5, gallery6, gallery7, gallery8, gallery9, news1, news2, news3, services3, servicesBg, whyus, whyus2 } from '../../assets/images'
import { useTranslation } from 'react-i18next';


const News = () => {

  const { t, i18n } = useTranslation();
  return (
    <>
    <HeaderOther/>  
      <div>
    <div className='md:max-w-container max-w-containerSm mx-auto'>
    <p className='mt-[20px] mb-[30px] lg:mb-[60px] text-sm lg:text-base'><span className='text-[#DDB14C]'>{t("Ana səhifə")}</span> / {t("Xəbərlər")}</p>
    <p className='mt-[20px] text-[20px] md:text-[32px] font-semibold text-center'>{t("Xəbərlər")}</p>
 <div className='flex mb-[40px] justify-between mt-[40px]'>
 <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8'>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news1} alt="Why Us"></img>
  <p className='mt-4'>{t("“Security Operation Services” şirkətinin əməkdaşları “YAŞAT” fonduna ianə ediblər")}</p>
</div>
</a>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news2} alt="Why Us"></img>
  <p className='mt-4'>{t("“Security Operation Services“ MMC “SOS” uşaq evini ziyarət edib, şəhid anaları ilə görüş keçirib")}</p>
</div>
</a>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news3} alt="Why Us"></img>
  <p className='mt-4'>{t("“Security Operation Services” MMC Gənclər Gününə həsr olunan tədbir keçirib")}</p>
</div>
</a>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news1} alt="Why Us"></img>
  <p className='mt-4'>{t("“Security Operation Services” şirkətinin əməkdaşları “YAŞAT” fonduna ianə ediblər")}</p>
</div>
</a>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news2} alt="Why Us"></img>
  <p className='mt-4'>{t("“Security Operation Services“ MMC “SOS” uşaq evini ziyarət edib, şəhid anaları ilə görüş keçirib")}</p>
</div>
</a>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news3} alt="Why Us"></img>
  <p className='mt-4'>{t("“Security Operation Services” MMC Gənclər Gününə həsr olunan tədbir keçirib")}</p>
</div>
</a>

 </div>
 </div>
    </div>
  </div>
    </>
  )
}

export default News

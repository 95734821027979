import React, { useState } from 'react';
import { logo, logoOther } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import { FiMenu, FiX } from 'react-icons/fi';

const Header = () => {

  const { t, i18n } = useTranslation();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);
  const [isSubMenuOpen3, setIsSubMenuOpen3] = useState(false);
  const [isSubMenuOpen4, setIsSubMenuOpen4] = useState(false);
  const [isSubMenuOpen5, setIsSubMenuOpen5] = useState(false);

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (menuIndex) => {
    setActiveMenu(activeMenu === menuIndex ? null : menuIndex);
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <> 
      <div className=' h-[80px] pt-5 text-[white] mb-16'>
        <div className='md:max-w-container max-w-containerSm mx-auto h-[45px] flex justify-between'> 
          <div className='h-[90px]'>
            <a className='mx-auto' href='/'><img className='mx-auto' alt='logo' height={59} width={46} src={logoOther}></img></a>
            <p className='text-[white] mt-1'>ISO 9001</p>
          </div>
          <div className='flex justify-center items-center'>
          <nav className='hidden md:block'>
  <ul className="flex space-x-8">
    <a href="/">
      <li className='text-[#DDB14C]'>{t("Ana səhifə")}</li>
    </a>
    <li 
                  className='relative'
                  onMouseEnter={() => setIsSubMenuOpen2(true)}
                  onMouseLeave={() => setIsSubMenuOpen2(false)}
                >
                  <a href='/services'><li className='cursor-pointer'>{t("Xidmətlər")}</li></a>
                  {isSubMenuOpen2 && (
                    <ul className='absolute left-0 bg-[white] text-[black] shadow-lg border border-gray-200 w-[250px]'>
                      <li><a href="/services/fiziki" className='block px-4 py-2 hover:bg-gray-100'>{t("Fiziki mühafizə")}</a></li>
                      <li><a href="/services/video" className='block px-4 py-2 hover:bg-gray-100'>{t("Video və dronla müşahidə")}</a></li>
                      <li><a href="/services/events" className='block px-4 py-2 hover:bg-gray-100'>{t("Tədbirlərin mühafizəsi")}</a></li>
                      <li><a href="/services/tehlukesizlik" className='block px-4 py-2 hover:bg-gray-100'>{t("Təhlükəsizlik sistemləri")}</a></li>
                    </ul>
                  )}
                </li>
                <li 
                  className='relative'
                  onMouseEnter={() => setIsSubMenuOpen3(true)}
                  onMouseLeave={() => setIsSubMenuOpen3(false)}
                >
                  <a href='/about'><li className='cursor-pointer'>{t("Haqqımızda")}</li></a>
                  {isSubMenuOpen3 && (
                    <ul className='absolute left-0 bg-[white] text-[black] shadow-lg border border-gray-200 w-[200px]'>
                      <li><a href="/about/partners" className='block px-4 py-2 hover:bg-gray-100'>{t("Tərəfdaşlar")}</a></li>
                      <li><a href="/about/projects" className='block px-4 py-2 hover:bg-gray-100'>{t("Sosial layihələr")}</a></li>
                    </ul>
                  )}
                </li>
    <a href="/news">
      <li>{t("Xəbərlər")}</li>
    </a>
    <a href="/laws">
      <li>{t("Qanunvericilik")}</li>
    </a>
    <li 
                  className='relative'
                  onMouseEnter={() => setIsSubMenuOpen4(true)}
                  onMouseLeave={() => setIsSubMenuOpen4(false)}
                >
                  <li className='cursor-pointer'>{t("Media")}</li>
                  {isSubMenuOpen4 && (
                    <ul className='absolute left-0 bg-[white] text-[black] shadow-lg border border-gray-200'>
                      <li><a href="/media/photo-gallery" className='block px-4 py-2 hover:bg-gray-100'>{t("Fotoqalereya")}</a></li>
                      <li><a href="/media/video" className='block px-4 py-2 hover:bg-gray-100'>{t("Video")}</a></li>
                    </ul>
                  )}
                </li>
                <li 
                  className='relative'
                  onMouseEnter={() => setIsSubMenuOpen5(true)}
                  onMouseLeave={() => setIsSubMenuOpen5(false)}
                >
                  <li className='cursor-pointer'>{t("Karyera")}</li>
                  {isSubMenuOpen5 && (
                    <ul className='absolute left-0 bg-[white] text-[black] shadow-lg border border-gray-200 w-[200px]'>
                      <li><a href="/karyera/experience" className='block px-4 py-2 hover:bg-gray-100'>{t("Təcrübə proqramları")}</a></li>
                      <li><a href="/karyera/jobs" className='block px-4 py-2 hover:bg-gray-100'>{t("Vakansiyalar")}</a></li>
                      <li><a href="/karyera/rules" className='block px-4 py-2 hover:bg-gray-100'>{t("İşə qəbul qaydaları")}</a></li>
                    </ul>
                  )}
                </li>
                <a href="/press">
      <li>{t("Mətbuat")}</li>
    </a>
    <a href="/contact">
      <li>{t("Əlaqə")}</li>
    </a>
  </ul>
</nav>
<button
            className="lg:hidden text-gray-700 mt-4"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <FiX size={28} color="#fff" /> : <FiMenu color="#fff" size={24} />}
          </button>
          {isMobileMenuOpen && ( 
            <div className="lg:hidden absolute top-0 left-0 w-full bg-[black] min-h-screen shadow-lg p-4">
             <div className='max-w-containerSm mx-auto'> 
             <div className='flex justify-end'>
             <button
            className="lg:hidden text-gray-700 mt-4"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <FiX size={28} color="#fff" /> : <FiMenu size={24} />}
          </button>
             </div>
              <ul className="space-y-4">
                <div className="w-full relative mb-5">
        <a href="/">
        <button
          className="w-full text-[white] rounded-lg text-left"
        >
          {t("Ana səhifə")}
        </button>
        </a>
      </div>
                <div className="w-full relative mb-5">
      <a href='/services'>
      <button
  onClick={() => toggleMenu('menu2')}
  className="w-full text-[white] rounded-lg flex items-center justify-between "
>
  <span>{t("Xidmətlər")}</span>
  <i className="fas fa-chevron-down text-white"></i>
</button>
      </a>

        
        {activeMenu === 'menu2' && (
          <div className="absolute left-0 right-0 mt-2 space-y-2 bg-gray-100 rounded shadow-lg z-50">
            <a href="/services/fiziki" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
              {t("Fiziki mühafizə")}
            </a>
            <a href="/services/video" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
              {t("Video və dronla müşahidə")}
            </a>
            <a href="/services/events" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
              {t("Tədbirlərin mühafizəsi")}
            </a>
            <a href="/services/tehlukesizlik" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
              {t("Təhlükəsizlik sistemləri")}
            </a>
          </div> 
        )}
      </div>
      <div className="w-full relative mb-5">
        <a href="/about">
        <button
          className="w-full text-[white] rounded-lg text-left"
        >
          {t("Haqqımızda")}
        </button>
        </a>
      </div>
      <div className="w-full relative mb-5">
        <a href="/news">
        <button
          className="w-full text-[white] rounded-lg text-left"
        >
          {t("Xəbərlər")}
        </button>
        </a>
      </div>
      <div className="w-full relative mb-5">
        <a href="/laws">
        <button
          className="w-full text-[white] rounded-lg text-left"
        >
          {t("Qanunvericilik")}
        </button>
        </a>
      </div>
      <div className="w-full relative mb-5">
      <button
  onClick={() => toggleMenu('menu3')}
  className="w-full text-[white] rounded-lg flex items-center justify-between "
>
  <span>{t("Media")}</span>
  <i className="fas fa-chevron-down text-white"></i>
</button>

        
        {activeMenu === 'menu3' && (
          <div className="absolute left-0 right-0 mt-2 space-y-2 bg-gray-100 rounded shadow-lg z-50">
            <a href="/media/photo-gallery" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
              {t("Fotoqalereya")}
            </a>
            <a href="/media/video" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
              {t("Video")}
            </a>
          </div> 
        )}
      </div>
      <div className="w-full relative mb-5">
      <button
  onClick={() => toggleMenu('menu4')}
  className="w-full text-[white] rounded-lg flex items-center justify-between "
>
  <span>{t("Karyera")}</span>
  <i className="fas fa-chevron-down text-white"></i>
</button>

        
        {activeMenu === 'menu4' && (
          <div className="absolute left-0 right-0 mt-2 space-y-2 bg-gray-100 rounded shadow-lg z-50">
            <a href="/karyera/experience" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
              {t("Təcrübə proqramları")}
            </a>
            <a href="/karyera/jobs" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
              {t("Vakansiyalar")}
            </a>
            <a href="/karyera/rules" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
              {t("İşə qəbul qaydaları")}
            </a>
          </div> 
        )}
      </div>
      <div className="w-full relative mb-5">
        <a href="/press">
        <button
          className="w-full text-[white] rounded-lg text-left"
        >
          {t("Mətbuat")}
        </button>
        </a>
      </div>
      <div className="w-full relative mb-5">
        <a href="/contact">
        <button
          className="w-full text-[white] rounded-lg text-left"
        >
          {t("Əlaqə")}
        </button>
        </a>
      </div>
              </ul>
             </div>
            </div>
          )}

          </div>
        </div>
      </div>
    </>
  );
};

export default Header;







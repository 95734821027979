import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Sayfalandırma stillerini yüklemek için
import { Pagination, Scrollbar } from 'swiper/modules';

import { partners1, partners10, partners11, partners12, partners13, partners14, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';

const Partners = () => {

  const { t, i18n } = useTranslation();
  return (
    <div className='h-[410px] w-full flex justify-center items-center'>
      <div className='md:max-w-container max-w-containerSm flex flex-col mx-auto justify-between h-[240px]'>
        <div className='text-[24px] md:text-[32px] text-center font-semibold'><p>{t("Tərəfdaşlar")}</p></div>
        <div className='h-[145px]'>
          <Swiper
            spaceBetween={30}  // Görseller arasındaki boşluk
            loop={true}        // Slider sonsuz döngüde olsun
            pagination={{ clickable: true }}  // Sayfalandırma noktalarını etkinleştir, tıklanabilir yap
            modules={[Pagination]}  // Pagination modülünü yükle
            breakpoints={{
              640: {  // Ekran genişliği 640px ve üzeri olduğunda
                slidesPerView: 2,  // 2 görsel gösterilsin
              },
              768: {  // Ekran genişliği 768px ve üzeri olduğunda
                slidesPerView: 3,  // 3 görsel gösterilsin
              },
              1024: {  // Ekran genişliği 1024px ve üzeri olduğunda
                slidesPerView: 4,  // 4 görsel gösterilsin
              },
              480: {  // Ekran genişliği 480px ve altı olduğunda
                slidesPerView: 1,  // Sadece 1 görsel gösterilsin
              },
            }}   // Pagination modülünü yükle
          >
            {/* Tüm partner görselleri için SwiperSlide bileşeni */}
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners1} alt="Partner 1" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners2} alt="Partner 2" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners3} alt="Partner 3" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners4} alt="Partner 4" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners5} alt="Partner 5" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners6} alt="Partner 6" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners7} alt="Partner 7" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners8} alt="Partner 8" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners9} alt="Partner 9" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners10} alt="Partner 10" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners11} alt="Partner 11" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners12} alt="Partner 12" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners13} alt="Partner 13" />
              </div>
            </SwiperSlide> 
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[66px] object-contain' src={partners14} alt="Partner 14" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Partners;

import React from 'react'
import ProductCard from './ProductCard';
import { certificate1, certificate2, certificate3, certificate4, certificate5, certificate6, huwasan, productBg, virus1, virus2, virus3, virus4, virus5, whyus, whyus2 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';


const WhyUs = () => {

  const { t, i18n } = useTranslation();
  return (
    <div className='bg-[black] text-[white] md:h-[500px]'>
    <div className='md:max-w-container max-w-containerSm mx-auto pt-20 pb-20'>
  <div className='md:text-center md:w-2/3 mx-auto'>
<p className=' text-[20px] md:text-[32px]'>{t("Bizi seçməyiniz üçün səbəblər")}</p> 
<p className='text-[#C5C5C5] italic text-base md:text-[18px]'>{t("Hədəfimiz – müasir dünyada qabaqcıl texnologiyanı tətbiq etməklə  daim inkişaf etmək, Məqsədimiz – tərəfdaş məmnuniyyəti qazanmaqdır!")}  </p>
  </div>
  <div className='flex mt-10'>
    <div>
   <div className='md:p-10 md:flex'>
   <div className='pb-5'>
      <p className='text-[18px]'>{t("Yerli və beynəlxalq təcrübə")}</p>
      <p className='text-[#C5C5C5]'>{t("Əməkdaşlarını mütamadi olaraq mühafizə obyektlərinin xüsusiyyətlərinə uyğun xüsusi (beynəlxalq və yerli) əhəmiyyətli kurslara cəlb etməklə “Security Operation Services” MMC  tərəfdaşlarına keyfiyyətli xidməti təqdim edir.")}  </p>
    </div>
    <div> 
      <p className='text-[18px]'>{t("Müasir Texnologiya")}</p>
      <p className='text-[#C5C5C5]'>{t("Müasir dünyada müstəsna yer tutan və gündəlik həyatımızın ayrılmaz parçasına çevrilən texnologiya və texnoloji avadanlıqların imkanlarını xidmətizə iteqrasiya etməklə daimi yenilənməni təmin edir və  peşəkar fəaliyyətimizi  həyata keçiririk.")}   </p>
    </div>
   </div>
    </div>
  </div>

    </div>
  </div>
  )
}

export default WhyUs
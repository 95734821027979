import React from 'react'
import HeaderOther from '../../Components/HeaderOther/HeaderOther'
import { certificate1, certificate2, certificate3, partners1, partners10, partners11, partners12, partners13, partners14, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, whyus, whyus2 } from '../../assets/images'
import { useTranslation } from 'react-i18next';

const JobsApply = () => {

  const { t, i18n } = useTranslation();
  return (
    <>
     <HeaderOther/>  
     <div className='md:max-w-container max-w-containerSm mx-auto'>
     <p className='mt-[20px] mb-[30px] lg:mb-[60px] text-sm lg:text-base'><span className='text-[#DDB14C]'>{t("Ana səhifə")}</span> / <span className='text-[#DDB14C]'>{t("Karyera")}</span> / <span className='text-[#DDB14C]'>{t("Vakansiyalar")}</span>  / <span >{t("Müraciət")}</span> </p>
     </div>
      <div className='md:max-w-container max-w-containerSm mx-auto md:mt-[77px] mb-[200px]'>
      <p className='text-black text-[24px] md:text-[32px] font-semibold mb-10'>{t("Müraciət formu")}</p>
      <div className="w-full lg:w-[60%]">
              <form className="space-y-6">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("Ad, soyad")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="bg-[#F1F1F1] mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      placeholder="Ad, soyad"
                    />
                  </div>
                </div>

                  <div>
                    <label
                      htmlFor="number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("Əlaqə nömrəsi")}
                    </label>
                    <input
                      type="number"
                      id="number"
                      className="bg-[#F1F1F1] mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      placeholder="Əlaqə nömrəsi"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("E-mail")}
                    </label>
                    <input
                      type="text"
                      id="email"
                      className="bg-[#F1F1F1] mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      placeholder="E-mail"
                    />
                  </div>

                <div>
                  <label
                    htmlFor="cv"
                    className="block text-sm font-medium text-gray-700"
                  >
                    CV
                  </label>
                  <input
                   type='file'
                    id="cv"
                    className="bg-[#F1F1F1] mt-2 block w-full px-4 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                  ></input>
                </div>

                <div className="flex justify-start">
                  <button
                    type="submit"
                    className="bg-[#DDB14C] px-8 py-3 text-white rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                  >
                    {t("Göndər")}
                  </button>
                </div>
              </form>
            </div>
      </div> 
    </> 
  )
}

export default JobsApply

import React from 'react'
import { blogLeft, blogRightBottom, blogRightTop, fiziki, leaf, productBg, servicesBg, whyus } from '../../assets/images';
import HeaderOther from '../../Components/HeaderOther/HeaderOther';
import { useTranslation } from 'react-i18next';

const ServicesFiziki = () => {

  const { t, i18n } = useTranslation();
  return (
   <>
   <HeaderOther/>
   <div className='md:max-w-container max-w-containerSm mx-auto'>
   <p className='mt-[20px]'><span className='text-[#DDB14C]'>{t("Ana səhifə")} / {t("Xidmətlər")}</span> / {t("Fiziki mühafizə")}</p>
   <p className='mt-[45px] text-[20px] md:text-[32px] font-semibold text-center md:hidden '>{t("Fiziki mühafizə")}</p>
   <div className='md:flex justify-between md:mt-[40px] mb-[100px]'>
   <div className="flex flex-col gap-3 md:w-[320px] mb-10 md:mb-0 ">
  <a href="/services/fiziki">
    <button disabled className="h-[51px] text-[white] bg-[#DDB14C] rounded-md mt-[85px] w-full text-left pl-4">
      {t("Fiziki mühafizə")}
    </button>
  </a>
  <a href="/services/video">
    <button className="h-[51px] bg-[#F1F1F1] rounded-md w-full text-left pl-4 hover:bg-[#DDB14C] hover:text-[white] transition-all duration-300 ease-in-out">
      {t("Video və dronla müşahidə")}
    </button>
  </a>
  <a href="/services/events">
    <button className="h-[51px] bg-[#F1F1F1] rounded-md w-full text-left pl-4 hover:bg-[#DDB14C] hover:text-[white] transition-all duration-300 ease-in-out">
      {t("Tədbirlərin mühafizəsi")}
    </button>
  </a>
  <a href="/services/tehlukesizlik">
    <button className="h-[51px] bg-[#F1F1F1] rounded-md w-full text-left pl-4 hover:bg-[#DDB14C] hover:text-[white] transition-all duration-300 ease-in-out">
      {t("Təhlükəsizlik sistemləri")}
    </button>
  </a>
</div>
    <div className='md:w-3/5 w-full'>
    <p className='mb-[45px] text-[32px] font-semibold hidden md:block'>{t("Fiziki mühafizə")}</p>
      <img src={fiziki}></img>
      <p className='mt-5'>{t("“Security Operation Services” MMC sifarişçi təşkilatlar qarşısındakı öhdəliklərinin müasir dövrün tələblərinə uyğunlaşdırmaqla, keyfiyyətli, dayanıqlı, operativ xidməti təmin etmək məqsədi ilə dövrü olaraq, yeni texnologiya və innovasiyaların imkanlarından istifadə etməklə həyata keçirir.  Elm və texnologiyanın yüksək sürətlə inkişaf etdiyi hazırkı dövrdə mühafizə sahəsində mümkün risklərin müəyyən edilməsi, onlara qarşı cavab tədbirlərinin görülməsi istiqamətində səmərəli fəaliyyətin təşkili ən vacib  məsələrdən hesab olunur. Sifarişçi məmuniyyətini nəzərə alaraq, fiziki mühafizə, tədbirlərin mühafizəsi,  video  müşahidə,  infrastruktur və obyektlərin mühafizəsində  yanğından mühafizə işinin təşkili, təhlükəsizlik və kamera sistemlərinin quraşdırılması, onlara texniki dəstəyin verilməsi də daxil olmaqla, müxtəlif istiqamətlər üzrə keyfiyyətli xidmət göstəririk")}</p>
    </div>
   </div>
   </div> 
  </>
  )
}

export default ServicesFiziki
import React, { useEffect } from 'react';
import Hero from '../../Components/home/Hero/Hero';
import About from '../../Components/home/About/About';
import Services from '../../Components/home/Services/Services';
import Blogs from '../../Components/home/Blogs/Blogs';
import Contact from '../../Components/home/Contact/Contact';
import Header from '../../Components/Header/Header';
import Awards from '../../Components/home/Awards/Awards';
import WhyUs from '../../Components/home/WhyUs/WhyUs';
import News from '../../Components/home/News/News';
import Partners from '../../Components/home/Partners/Partners';
import AboveHeader from '../../Components/AboveHeader/AboveHeader';
import { heroVideo } from '../../assets/images';
import { useNavigate } from 'react-router-dom';



const Home = () => {


  return (
    <> 
     

<div className='bg-black/60' style={{ position: 'relative', overflow: 'hidden' }}>
<video
  autoPlay
  loop
  muted
  playsInline
  style={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1
  }}
>
  <source src={heroVideo} type="video/mp4" />
  {/* You can add more <source> tags if you have other video formats like webm */}
  Your browser does not support the video tag.
</video>

<AboveHeader />
<Header />
<Hero />
</div>

      {/* <Awards /> */}
      <About />
      <WhyUs />
      <Services />
      <Contact />
      <News />
      <Partners />
    </>
  );
}

export default Home;















































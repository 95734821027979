import React from 'react'
import HeaderOther from '../../Components/HeaderOther/HeaderOther'
import { certificate1, certificate2, certificate3, partners1, partners10, partners11, partners12, partners13, partners14, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, whyus, whyus2 } from '../../assets/images'
import { useTranslation } from 'react-i18next';

const Rules = () => {

  const { t, i18n } = useTranslation();
  return (
    <>
     <HeaderOther/>
     <div className='md:max-w-container max-w-containerSm mx-auto'>
     <p className='mt-[20px] mb-[30px] lg:mb-[60px] text-sm lg:text-base'><span className='text-[#DDB14C]'>{t("Ana səhifə")}</span> / <span className='text-[#DDB14C]'>{t("Haqqımızda")}</span> /  {t("İşə qəbul qaydaları")}</p>
     </div>
     <div className=''>  
      <div className='md:max-w-container max-w-containerSm mx-auto'>
          <p className='text-[24px] lg:text-[32px] text-center mb-[30px] lg:mb-[40px] font-semibold'>{t("İşə qəbul qaydaları")}</p>   
      </div> 
    </div>
    <div className='text-[black] h-auto lg:h-[160px] mb-[40px] lg:mb-[283px]'>
      <div className='md:max-w-container max-w-containerSm lg: mx-auto'>
     <p>
     {t("Peşəkar mühafizə şirkəti olan “Security Operation Services' (SOS) MMC mühafizəçi peşəsi üzrə işə qəbulla bağlı şirkətin Təlim Tədris və Serifikatlaşdırma mərkəzində (TTSM) təşkil olunacaq icbari təlim kurslarında tədris proqramı 80 saat olmaqla 15 gün müddətində keçirilir və icbari təlim kursunu müvəffəqiyyətlə başa vuran şəxlərə mühafizəçi kursunu bitirmələrinə dair şəhadətnamə təqdim olunur. Sonraki mərhələdə onlarla əmək qanunvericiliyinin tələblərinə müvafiq şəkildə müddətli əmək müqaviləsi bağlanaraq boş vakant iş yerləri üzrə mərhələli işə qəbul olunmaları həyata keçirilir.")}
     </p>
      </div>
    </div>
    </>
  )
}

export default Rules

import React from 'react';
import { whyus } from '../../../assets/images';
import { useTranslation } from 'react-i18next';

const About = () => {

  const { t, i18n } = useTranslation();

  return (
    <div className=''> 
    <div className='md:max-w-container max-w-containerSm mx-auto'>
      <div className='  mt-[100px] mb-[100px]'>
      <p className='text-[24px] md:text-[32px] font-medium text-center mb-[50px]'>{t("Haqqımızda")}</p>
       <div className='md:flex justify-between'>
       <div className='md:w-1/3'> 
          <img className='h-[436px]' src={whyus} alt="Why Us"></img>
        </div>
        <div className='md:w-3/5 flex flex-col justify-center mt-10 md:mt-0 '>
          <p>
          {t("'Security Operation Services' MMC Azərbaycan Respublikası Daxili İşlər Nazirliyinin 24 iyul 2020-ci il tarixli, 108 nömrəli Lisenziyası əsasında 2020-ci ilin noyabr ayının 1 dən 'Qeyri-dövlət (özəl) mühafizə fəaliyyəti haqqında Azərbaycan Respublikasının Qanununa müvafiq olaraq mühafizə xidmətini həyata keçirən özəl mühafizə şirkətidir. 'Security Operation Services' MMC Azərbaycan Respublikası Daxili İşlər Nazirliyinin 24 iyul 2020-ci il tarixli, 108 nömrəli Lisenziyası əsasında 2020-ci ilin noyabr ayının 1 dən 'Qeyri-dövlət (özəl) mühafizə fəaliyyəti haqqında Azərbaycan Respublikasının Qanununa müvafiq olaraq mühafizə xidmətini həyata keçirən özəl mühafizə şirkətidir.")}
          </p>
         <a href='/about'> <p className='text-[#DDB14C] mt-3'>{t("Ətraflı bax")} <i class="fa-solid fa-arrow-right"></i></p></a>
        </div>
       </div>
      </div>
    </div> 
  </div>
  );
};

export default About;

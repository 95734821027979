import React from 'react'
import { blogLeft, blogRightBottom, blogRightTop, leaf, productBg, servicesBg, tehluksizlik, whyus } from '../../assets/images';
import HeaderOther from '../../Components/HeaderOther/HeaderOther';
import { useTranslation } from 'react-i18next';

const ServicesTehlukesizlik = () => {

  const { t, i18n } = useTranslation();
  return (
   <>
   <HeaderOther/>
   <div className='md:max-w-container max-w-containerSm mx-auto'>
   <p className='mt-[20px]'><span className='text-[#DDB14C]'>{t("Ana səhifə")} / {t("Xidmətlər")}</span> / {t("Təhlükəsizlik sistemləri")}</p>
   <p className='mt-[45px] text-[20px] md:text-[32px] font-semibold text-center md:hidden '>{t("Təhlükəsizlik sistemləri")}</p>
   <div className='md:flex justify-between md:mt-[40px] mb-[100px]'>
   <div className="flex flex-col gap-3 md:w-[320px] mb-10 md:mb-0">
   <a href="/services/fiziki">
    <button className="h-[51px] bg-[#F1F1F1] rounded-md mt-[85px] w-full text-left pl-4 hover:bg-[#DDB14C] hover:text-[white] transition-all duration-300 ease-in-out">
    {t("Fiziki mühafizə")}
    </button>
  </a>
  <a href="/services/video">
  <button className="h-[51px] bg-[#F1F1F1] rounded-md w-full text-left pl-4 hover:bg-[#DDB14C] hover:text-[white] transition-all duration-300 ease-in-out">
      {t("Video və dronla müşahidə")}
    </button>
  </a>
  <a href="/services/events">
  <button className="h-[51px] bg-[#F1F1F1] rounded-md w-full text-left pl-4 hover:bg-[#DDB14C] hover:text-[white] transition-all duration-300 ease-in-out">
      {t("Tədbirlərin mühafizəsi ")}
    </button>
  </a>
  <a href="/services/tehlukesizlik">
    <button disabled className="h-[51px] text-[white] bg-[#DDB14C] rounded-md w-full text-left pl-4">
  {t("Təhlükəsizlik sistemləri")}
    </button>
  </a>
</div>
    <div className='md:w-3/5 w-full'>
    <p className='mb-[45px] text-[32px] font-semibold hidden md:block'>{t("Təhlükəsizlik sistemləri")}</p> 
      <img src={tehluksizlik}></img>
      <p className='mt-5'>{t("“Security Operation Services” MMC sifarişçi təşkilatlar qarşısındakı öhdəliklərinin müasir dövrün tələblərinə uyğunlaşdırmaqla, keyfiyyətli, dayanıqlı, operativ xidməti təmin etmək məqsədi ilə dövrü olaraq, yeni texnologiya və innovasiyaların imkanlarından istifadə etməklə həyata keçirir.  Elm və texnologiyanın yüksək sürətlə inkişaf etdiyi hazırkı dövrdə mühafizə sahəsində mümkün risklərin müəyyən edilməsi, onlara qarşı cavab tədbirlərinin görülməsi istiqamətində səmərəli fəaliyyətin təşkili ən vacib  məsələrdən hesab olunur. Sifarişçi məmuniyyətini nəzərə alaraq, fiziki mühafizə, tədbirlərin mühafizəsi,  video  müşahidə,  infrastruktur və obyektlərin mühafizəsində  yanğından mühafizə işinin təşkili, təhlükəsizlik və kamera sistemlərinin quraşdırılması, onlara texniki dəstəyin verilməsi də daxil olmaqla, müxtəlif istiqamətlər üzrə keyfiyyətli xidmət göstəririk")}</p>
    </div>
   </div>
   </div>
  </>
  )
}

export default ServicesTehlukesizlik
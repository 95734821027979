import React, { useEffect, useState } from 'react';
import HeaderOther from '../../Components/HeaderOther/HeaderOther';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Videos = () => {

  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.sossecurity.az/sossecurity/videos.php/", {
        withCredentials: false,
      })
      .then((response) => {
        setProducts(response.data);
        setIsLoading(false);
      });
  }

  return (
    <>
      <HeaderOther />
      <div className='md:max-w-container max-w-containerSm mx-auto'>
        <p className='mt-[20px] mb-[30px] lg:mb-[60px] text-sm lg:text-base'>
          <span className='text-[#DDB14C]'>{t("Ana səhifə")} /</span> <span className='text-[#DDB14C]'>{t("Media")}</span> / {t("Video")}
        </p>
        <p className='mt-[20px] text-[20px] md:text-[32px] font-semibold text-center'>{t("Media")}</p>
        <div className='flex mb-[40px] justify-between mt-[40px]'>
          <div className='grid md:grid-cols-3 grid-cols-1 gap-5'>
            {products?.map((user, key) => (
              <div key={key}>

<video  className='h-[245px] w-full object-cover' controls>
        <source  src={`https://sossecurity.az/sossecurity/${user.content}`} type="video/mp4" />
        Tarayıcınız video etiketini desteklemiyor.
      </video>
      <p className='mt-4 text-base md:text-lg font-medium'>{user.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Videos;
